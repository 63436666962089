@import '@/assets/scss/_colors.scss';
@import '@/assets/scss/_variables.scss';

.Withdraw_root {
  background: $primaryAppSectionBg;
  min-height: $page-container-height;
}

.Withdraw_backBtn {
  text-align: left;
  margin-bottom: 26px;

  &.RoundButton_root {
    display: none;

    @media only screen and (min-width: $tablet-width) {
      display: flex;
    }
  }
}

@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';






























































































































































































.input-row {
  display: grid;
  grid-gap: 15px;
  margin: 0 0 15px;

  @media only screen and (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }
}

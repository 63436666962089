@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';



























































































.WalletMethod_Confirm::v-deep {
    .theme--dark.v-input--is-disabled textarea {
      color: #fff;
    }
  }

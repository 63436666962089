@import '@/assets/scss/_variables.scss';

.WithdrawCrypto_root {
  .WithdrawCrypto_container {
    @media only screen and (min-width: $tablet-width) {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
  }

  .WithdrawCrypto_form {
    flex-grow: 1;
    margin: 0 0 20px;
    @media only screen and (min-width: $tablet-width) {
      margin: 0 30px 0 0;
    }
  }

  .WithdrawCrypto_faq {
    flex-shrink: 0;
    @media only screen and (min-width: $tablet-width) {
      flex-basis: 390px;
    }
  }

  .WithdrawCrypto_row {
    margin-bottom: 15px;
    &:last-of-type {
      margin-bottom: 22px;
    }
  }

  .SelectWrapper {
    .v-menu__content {
      max-width: 100%;
      width: 100%;
    }
  }

  .TextBtn_root {
    font-size: 12px;
    line-height: 14px;
    color: #2af3f3;
    margin-top: 10px;
    cursor: pointer;
    display: flex;
    text-align: left;
    svg {
      margin-right: 2px;
      vertical-align: middle;
    }
  }
}
@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';









































































































































































































































































































@import "./WithdrawGateway.scss";

.TextBtn_root {
  font-size: 12px;
  line-height: 14px;
  color: #2af3f3;
  margin-top: 10px;
  cursor: pointer;
  display: flex;
  text-align: left;

  svg {
    margin-right: 2px;
    vertical-align: middle;
  }
}

@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';


































































































.toggle-btn-text {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(0deg);
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 4px 0 4px;
    border-color: #2af3f3 transparent transparent transparent;
  }

  &.toggle-btn-text-active {
    &:after {
      transform: translateY(-50%) rotate(180deg);
    }
  }
}
.dropdown-filter {
  opacity: 0;
  visibility: hidden;

  &.open {
    opacity: 1;
    visibility: visible;
  }
}

@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';




















































































































@import "./WithdrawCrypto/WithdrawCrypto.scss";

.WithdrawCrypto_row-btns {
  display: flex;
  justify-content: space-between;
  align-items: center;

  button,
  .WithdrawCrypto_row-btn {
    flex: 0 1 48%;
    position: relative;
  }
}

.WithdrawCrypto_title {
  font-weight: 500;
  font-size: 21px;
  line-height: 25px;
  text-align: left;
  text-transform: uppercase;
  color: #ffffff;
  padding-bottom: 12px;
  border-bottom: 1px solid rgba(#2af3f3, 0.1);
  margin-bottom: 12px;
}

@import '@/assets/scss/_variables.scss';

.WithdrawCrypto_container {
  @media only screen and (min-width: $tablet-width) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.WithdrawCrypto_form {
  flex-grow: 1;
  margin: 0 0 20px;
  @media only screen and (min-width: $tablet-width) {
    margin: 0 30px 0 0;
  }
}

.WithdrawCrypto_verification {
  flex-grow: 1;
  margin: 0 0 20px;
  @media only screen and (min-width: $tablet-width) {
    margin: 0 30px 0 0;
  }
}

.WithdrawCrypto_faq {
  @media only screen and (min-width: $tablet-width) {
    flex-basis: 390px;
  }
}

.WithdrawCrypto_row {
  margin-bottom: 15px;
  &:last-of-type {
    margin-bottom: 25px;
  }
}
